import React from "react";
import { graphql } from "gatsby";
import loadable from "@loadable/component";

const Layout = loadable(() => import("@common/Layout/Black-White"));
const Blog = loadable(() => import("@components/BW-Blog"));
const SEO = loadable(() => import("@components/Common/SEO/SEO"));

const BlogPage = ({ path, data, location }) => (
    <Layout path={path}>
        <SEO
            title="Read Our Professional Software Development Company Blog"
            titleTemplate="Impressit"
            description="There is no progress without knowledge sharing. Check our software development company blog to find out more about start ups, development, and the IT world."
            path={path}
            location={location}
        />
        <Blog data={data} />
    </Layout>
);

export default BlogPage;

export const blogPageQuery = graphql`
    query BlogQuery {
        allStrapiCategories(sort: {fields: order}) {
            edges {
              node {
                name
                order
              }
            }
          }
        allStrapiArticle {
            edges {
                node {
                    id
                    title
                    content
                    exctract_content
                    date
                    read_time
                    meta_url
                    meta_title
                    meta_keywords
                    meta_description
                    author {
                        name
                        avatar {
                            alternativeText
                            formats {
                                thumbnail {
                                    url
                                }
                            }
                            name
                        }
                        role
                    }
                    cover {
                        alternativeText
                        formats {
                            large {
                                url
                            }
                            medium {
                                url
                            }
                            small {
                                url
                            }
                        }
                    }
                    categories {
                        name
                    }
                }
            }
        }
    }
`;
